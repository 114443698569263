import { useEffect, useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import getInitialValues from './form/getInitialValues';
import Loader from '~/ui/components/common/Loader';
import UserForm from '~/ui/components/reusable/UserForm';

import { IUser } from '~/services/api/users/types';
import { IUserFormValues } from '~/ui/components/reusable/UserForm/types';
import styles from './EditUser.module.scss';

interface IProps {
  userId: number;
  onClose: () => void;
}

const EditUser = ({ userId, onClose }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isUserUpdating, setIsUserUpdating] = useState(false);

  const user = useStoreState(state => state.users.item);
  const { onGetUser, onUpdateUser } = useStoreActions(actions => actions.users);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const updateUser = async (data: IUserFormValues) => {
    const {
      roleId,
      firstName,
      lastName,
      email,
      phone,
      shouldEditPassword,
      password,
      programIds,
      permissions,
      isActive,
      isMfaEnabled,
      isMobileEnabled,
      hasAgreed,
      receiveMissedReadingNotifications,
    } = data;

    const payload: IUser = {
      id: userId,
      roleId,
      firstName,
      lastName,
      email,
      phone,
      programIds,
      permissions,
      isActive,
      isMfaEnabled,
      isMobileEnabled,
      hasAgreed,
      receiveMissedReadingNotifications,
      ...(shouldEditPassword && { password }),
    };

    setIsUserUpdating(true);

    try {
      await onUpdateUser(payload);

      showNotify('User successfully updated');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsUserUpdating(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when user is updating
    if (isUserUpdating) return;
    onClose();
  };

  const onMount = async () => {
    try {
      await onGetUser(userId);
      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal open title="Update User" className={styles.modal} onClose={handleClose}>
      {isLoading ? (
        <Loader />
      ) : (
        <UserForm
          mode="edit"
          initialValues={getInitialValues(user)}
          isProcessing={isUserUpdating}
          onSubmit={updateUser}
        />
      )}
    </Modal>
  );
};

export default EditUser;
